<template>
   <div class="flex flex-col gap-4">
      <!--  -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 ">
         <div class="flex flex-col gap-4">
            <q-turnover />

            <q-bank />
         </div>

         <div class="flex flex-col gap-4 relative">
            <card-with-titile
               title="Recette et depense"
               :extraClass="'py-[24px] '"
            >
               <template #content>
                <div class="flex justify-center items-center h-full ">
                    <highcharts
                     v-if="isDisplayChart"
                     class="w-full"
                     :options="chartOptions"
                  ></highcharts>
                </div>
               </template>
            </card-with-titile>
         </div>
      </div>

      <div class=" grid grid-cols-1 md:grid-cols-2 gap-4 ">
         <!--  -->
         <q-invoice />

         <!--  -->
         <q-quote />
      </div>

      <!--  -->
      <q-top />
   </div>
</template>

<script>
import cardWithTitile from './__partiels/cardWithTitile.vue';
import CfCard from './__partiels/cfCard.vue';
import Chart from 'chart.js';
import { onMounted, reactive, ref } from '@vue/composition-api';
import QTurnover from './components/qTurnover.vue';
import QInvoice from './components/qInvoice.vue';
import QQuote from './components/qQuote.vue';
import URL from '../request';
import axios from 'axios';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';
import CardTrnoverTop from './__partiels/cardTrnoverTop.vue';
import QBank from './components/qBank.vue';
import QTop from './components/qTop.vue';

export default {
   components: {
      cardWithTitile,
      CfCard,
      QTurnover,
      QInvoice,
      QQuote,
      CardTrnoverTop,
      QBank,
      QTop,
   },

   props: [''],

   setup(props, { root }) {
      const isDisplayChart = ref(false);
      const chartOptions = reactive({
         chart: {
            type: 'column',
         },

         title: {
            text: '',
         },
         yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
               text: 'Montants',
            },
         },
         xAxis: {
            categories: [
               'Jan',
               'Fev',
               'Mar',
               'Avr',
               'Mai',
               'Juin',
               'Jul',
               'Août',
               'Setp',
               'Oct',
               'Nov',
               'Dec',
            ],
         },
         series: [
            {
               name: 'Entrés',
               data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // sample data
            },
            {
               name: 'Sorties',
               data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
               color: '#ef4444', // sample data
            },
         ],
      });

      const formatter = (amount) => {
         const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
      };

      onMounted(async() => {
        await qGetDataStatic();
      });

      //
      const qGetDataStatic = async () => {
         try {
            const { data } = await axios.get(URL.DASHBOARD_STATSTIC);
            if (data) {
                qExpense(data);
                qRecipe(data);
            }
         } catch (error) {
            console.log(error);
         }
      };

      //
      const qDefinedStatistic = (data, statsName, seriesIndex) => {
         for (let o = 0; o < data[statsName].length; o++) {
            const stats = data[statsName][o];
            const getMonth = parseInt(stats.mois.split('-')[0]) - 1;

            for (let i = 0; i < chartOptions.xAxis.categories.length; i++) {
               const chart = chartOptions.xAxis.categories[i];
               console.log(getMonth, i + 1);

               if (i + 1 === getMonth) {
                  chartOptions.series[seriesIndex].data[getMonth] = stats.montant;
                 
               }
            }
         }
          isDisplayChart.value = true;
      };

      //

      const qRecipe =  (dataRecipe) => {
         qDefinedStatistic(dataRecipe, 'recette', 0);
      };

      //
      const qExpense =  (dataExpense) => {
         qDefinedStatistic(dataExpense, 'depense', 1);
      };

      return { chartOptions, isDisplayChart };
   },
};
</script>

<style></style>
