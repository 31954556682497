<template>
   <div class="w-full relative ">
               <card-with-titile :extraClass="'py-[0px] '" :title="'Mes comptes ' ">
                  <template #content>
                     <highcharts v-if="isDisplayBank" :options="chartOptionsCompte"></highcharts>
                     <div class="flex justify-center ">
                        <button class="bg-yellow-300 text-white px-[12px] py-[6px] text-xl font-[700] "> Solde {{xTotalAccountBank}} </button>
                     </div>
                  </template>
               </card-with-titile>
            </div>
</template>

<script>
import axios from 'axios';
import URL from '../../request';
import { onMounted, reactive, ref } from '@vue/composition-api';
import cardWithTitile from '../__partiels/cardWithTitile.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';

export default {
   components: { cardWithTitile },

   props: [],

   setup(props, { root }) {

      const isDisplayBank = ref(false)
      const chartOptionsCompte = reactive({
         chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
         },
         title: {
            text: '',
         },
         tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
         },
         accessibility: {
            point: {
               valueSuffix: '%',
            },
         },
         plotOptions: {
            pie: {
               allowPointSelect: true,
               cursor: 'pointer',
               dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  connectorColor: 'silver',
               },
            },
         },
         series: [
            {
               name: 'Comptes',
               data: [
                  // { name: 'Chrome', y: 61.41 },
                  // { name: 'Internet Explorer', y: 11.84 },
                  // { name: 'Firefox', y: 10.85 },
                  // { name: 'Edge', y: 4.67 },
                  // { name: 'Safari', y: 4.18 },
                  // { name: 'Other', y: 7.05 },
               ],
            },
         ],
      });

      const xTotalAccountBank = ref(0)


         //
      onMounted(() => {
         qBank();
      });

      const formatter = (amount) => {
         const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
      };



    const qBank = async () => {
         const { data } = await axios.get(URL.DASHBOARD_BANK);
         // console.log(data);
         if (data) {
            xTotalAccountBank.value = formatter(data.total_solde) 
            data.compte.forEach((el) => {

               chartOptionsCompte.series[0].data.push({
                  name:
                     el.libelle.toUpperCase() + ': ' + formatter(el.solde),
                  y: el.solde,
               });
            });
         }
         isDisplayBank.value = true
      };

  

   

    
      return {
         chartOptionsCompte,
         qBank,
         isDisplayBank,
         xTotalAccountBank
      };
   },
};
</script>

<style></style>
