<template>
   <div class="h-full">

<!--  -->
    <div class=" flex flex-col gap-2 justify-center  h-full text-center">

      
        <!-- Display Month -->
        <div class=" flex justify-center bg-yellow-300 rounded py-[4px] "> 
            <span class="text-white text-[12px]  px-[16px] font-[700] ">{{month}}</span>
        </div>

        <!-- Display amount -->
        <div class=" text-[18px] font-[700] ">
            <span class="text-purple-900">{{amount}} </span>
        </div>

        <!-- progress to follow -->
        <div class="flex flex-col">
            <div class="flex justify-center gap-[0.15em] items-center">
                <feather-icon :icon="percent >= 0 ? 'ArrowUpRightIcon' : 'ArrowDownLeftIcon'" :class="percent >= 0 ? 'text-green-500' : 'text-red-500' " class=" font-[900] " size="16" />
            <span class=" font-[900] text-[20px]  " :class="percent >= 0 ? 'text-green-500' : 'text-red-500' "> {{percent}}% </span>
            </div>
            <span class="text-[8px] font-[900] text-purple-900 " > {{progressingText}} </span>
        </div>

    </div>



   </div>
</template>

<script>
import axios from 'axios';
import URL from '../../request';
import { onMounted } from '@vue/composition-api';


export default {
  components: {  },

    props: ["month", 'amount', 'percent', 'progressingText'],

    setup(props, {root}){

        // 
        onMounted(() => {
         
        })



        


        return {

        }

    }

};
</script>

<style></style>
