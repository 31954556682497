<template>
   <div class="h-full">
      <div v-for="(item, index) in data" :key="index">
         <div
            class="flex justify-between items-center  p-[8px] "
            :class="
               index === data.length - 1 ? 'border-b-[0px]' : 'border-b-[0.5px]'
            "
         >

         
            <div class="flex justify-center items-center gap-4">
               
                <feather-icon :class="item.color" v-if="type === 'clients'"  :icon="'UserIcon'" class=" h-16  " />
                <feather-icon :class="item.color" v-if="type === 'archives'"  :icon="'ArchiveIcon'" class=" h-16 " />
                <feather-icon :class="item.color" v-if="type === 'others'"  :icon="'BookmarkIcon'" class=" h-16  " />

              <div class="flex flex-col">
               <span class="text-[14px] font-[500]  " :class="item.color">
                  {{ item.libelle }}
               </span>
               <span :class="item.color+'/70'" class="text-[12px]  " > {{ item.subLibelle }} </span>
              </div>
            </div>

            <div class="">
               <span class="text-[14px] font-[700]  text-purple-900">
                  {{ item.turnover }}
               </span>
            </div>
         </div>
      </div>

      <div
         v-if="data.length === 0"
         class=" w-full h-full flex flex-col justify-center items-center  gap-2"
      >
         <img style="width: 10em" src="/empty.svg" alt="" />

         <span class="text-[12px] text-center">
            Aucune donnée, n'a été trouver
         </span>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      data: {
         type: Array,
      },
      type: {
         type: String
      }
   },

   setup(props, { root }) {
      return {};
   },
};
</script>

<style></style>
