<template>
   <div class="grid grid-cols-1 md:grid-cols-2  gap-4">
      <!--  -->

      <card-with-titile title="Top 5 des ventes en CA">
         <template #content>
            <card-trnover-top type="others" :data="xIntoCA.top_vente_ca" />
         </template>
      </card-with-titile>

      <card-with-titile title="Top 5 des articles vendus en CA">
         <template #content>
            <card-trnover-top type="others" :data="xIntoCA.top_article" />
         </template>
      </card-with-titile>

      <card-with-titile title="Top 5 des clients en CA">
         <template #content>
            <card-trnover-top type="clients" :data="xIntoCA.top_client" />
         </template>
      </card-with-titile>

      <card-with-titile title="Récentes activités">
         <template #content>
            <card-trnover-top type="archives" :data="xIntoCA.top_archive" />
         </template>
      </card-with-titile>
   </div>
</template>

<script>
import axios from 'axios';
import URL from '../../request';
import { onMounted, reactive } from '@vue/composition-api';
import cardWithTitile from '../__partiels/cardWithTitile.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';
import moment from 'moment';
import CardTrnoverTop from '../__partiels/cardTrnoverTop.vue';

export default {
   components: { cardWithTitile, CardTrnoverTop },

   props: [],

   setup(props, { root }) {
      const xIntoCA = reactive({
         top_vente_ca: [],
         top_article: [],
         top_client: [],
         top_archive: [],
      });

      //
      onMounted(() => {
         Activity();
         qGetTopData();
      });

      const formatter = (amount) => {
         const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
      };

      //
      const qGetTopData = async () => {
         try {
            const { data } = await axios.get(URL.DASHBOARD_TOP);
            if (data) {
               const XkeyArray = Object.keys(xIntoCA);
               await qGetTopSallers(data, XkeyArray);
               await qGetTopConsomers(data, XkeyArray);
               await qGetTopArticles(data, XkeyArray);
            }
         } catch (error) {
            console.log(error);
         }
      };

      //
      const qGetTopSallers = async (data, XkeyArray) => {
         for (let i = 0; i < data[0][XkeyArray[0]].length; i++) {
            const el = data[0][XkeyArray[0]][i];

            xIntoCA[XkeyArray[0]].push({
               libelle: el.libelle,
               subLibelle: 'Produits vendus',
               turnover: formatter(el.total_ttc),
               color: 'text-gray-900/80'
            });
         }
      };

      //
      const qGetTopArticles = async (data, XkeyArray) => {
         const valueTopArticles = Object.values(data[0].top_article);
         for (let i = 0; i < valueTopArticles.length; i++) {
            const el = valueTopArticles[i];
            // console.log('el', el);

            xIntoCA[XkeyArray[1]].push({
               libelle: el[0].libelle,
               subLibelle: el[0].parametre.libelle,
               turnover:
                  el[0].factures_sum_article_facturequantite + ' Articles',
                  color: 'text-gray-900/80'
            });
         }
      };

      //
      const qGetTopConsomers = async (data, XkeyArray) => {
         for (let i = 0; i < data[0][XkeyArray[2]].length; i++) {
            const el = data[0][XkeyArray[2]];
            console.log('el', el);

            xIntoCA[XkeyArray[2]].push({
               libelle: el[0].factures.client.nom,
               subLibelle: el[0].factures.client.contact,
               turnover: formatter(el[0].montant),
                color: 'text-gray-900/80'
            });
         }
      };

      const ol = async (des) => {
         const fil = `${des}`.split('-');
         const firstIndex = fil[0];
         if (fil[1] === 'update') {
            return {
               libelle: 'Modification de ' + fil[0],
               sub: fil[0],
               color: 'text-blue-500'
            };
         } else if (fil[1] === 'create') {
             return {
               libelle: 'Creation de ' + fil[0],
               sub: fil[0],
               color: 'text-green-500'
            };
         }else if (fil[1] === 'facture') {
             return {
               libelle:  fil[0] + ' de facture',
               sub: fil[0],
               color: 'text-orange-500'
            };
         }
         else if (fil[1] === 'destroy') {
             return {
               libelle: 'Suppression de ' + fil[0],
               sub: fil[0],
               color: 'text-red-500'
            };
         }else{
            return {
               libelle: 'Liste ' + fil[0],
               sub: fil[0],
            };
         }

         
      };

      const Activity = async () => {
         const { data } = await axios.get(URL.DASHBOARD_ACTIVITY);

         for (let i = 0; i < data.activity_recente.length; i++) {
            const el = data.activity_recente[i];

            xIntoCA['top_archive'].push({
               libelle: (await ol(el.description)).libelle,
               subLibelle:  (await ol(el.description)).sub,
               turnover: moment(el.created_at).locale("fr").startOf('hour').fromNow(),
               color: (await ol(el.description)).color
                  
            });
         }
      };

      return {
         xIntoCA,
      };
   },
};
</script>

<style></style>
