<template>
   <div >

<card-with-titile title="Factures">
               <template #content>
                  <div class="w-full grid grid-cols-2 ">
                     <div v-for="(item, index) in xInvoiceInfo" :key="index">
                        
                        <div class="flex flex-col gap-2 ">
                              <div class="flex justify-between items-center py-[16px] px-[4px] " :class="index === 0 ? 'border-r-[0.5px] border-b-[0.5px] ' : 'border-l-[0px]'">
                              <span class="text-[10px] w-7/12  text-purple-900">{{ item.label }} </span>
                              <span class="text-[32px] font-[800] " :class="index === 1 ? 'text-red-500' : 'text-purple-900' "> {{ item.number }} </span>
                           </div>
                       

                        <div class="flex flex-col  py-[16px] px-[4px]" :class="index === 0 ? 'border-r-[0px] ' : 'border-l-[0.5px] border-t-[0.5px] '">
                           <span class="text-[24px] font-[800] text-purple-900"> {{ item.price }} </span>
                           <span class="text-[10px]  w-7/12 text-purple-900">{{ item.label_price }} </span>
                        </div>
                        </div>

                     </div>
                  </div>
               </template>
            </card-with-titile>


   </div>
</template>

<script>
import axios from 'axios';
import URL from '../../request';
import { onMounted, reactive } from '@vue/composition-api';
import cardWithTitile from '../__partiels/cardWithTitile.vue';
import cfCard from '../__partiels/cfCard.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';


export default {
 components: { cardWithTitile, cfCard},

    props: ["month", 'amount', 'percent'],

    setup(props, {root}){


        const xInvoiceInfo = reactive([
        {
            number: '0',
            label: 'Nombre de factures en attentes de paiements',
            label_price: 'Valeur des factures en attentes de paiements',
            price: 0
        },{
            number: '0',
            label: 'Nombre de factures en retart de paiements',
            label_price: 'Valeur des factures en retart de paiements',
            price: 0
        }
      ])

        // 
        onMounted(() => {
           qInvoice()
        })


const formatter = (amount) => {
            const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

  
        const qInvoice = async() => {
            const {data} =  await axios.get(URL.DASHBOARD_INVOICE)
            console.log(data)
            xInvoiceInfo[0].number = data.facture_a_payer
            xInvoiceInfo[0].price = formatter(data.montant_facture_a_payer);

            xInvoiceInfo[1].number = data.facture_en_retard
            xInvoiceInfo[1].price = formatter(data.montant_facture_en_retard);
        }


        return {
            xInvoiceInfo
        }

    }

};
</script>

<style></style>
