<template>
   <div class="h-full">
      <div class="w-full h-full bg-white rounded">
         <!-- Head to display title -->
         <div class="w-full m-auto text-center bg-purple-900/90 rounded-t py-[8px]" >
            <span class="text-[14px] font-[800] capitalize text-white  "> {{title}} </span>
         </div>

         <!-- Slots to put all compoments  -->
         <div class="h-full" :class="extraClass">
            <slot name="content"> </slot>
         </div>
      </div>
   </div>
</template>

<script>
export default {

    props: {
      title: {
         type: String
      },
      extraClass: {
         type: String,
         default: 'p-8'
      }
    },

    setup(props, {root}){

        return {

        }

    }

};
</script>

<style></style>
