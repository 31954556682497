<template>
   <div class="h-full">
      <card-with-titile :extraClass="'px-8'" title="Devis">
         <template #content>
            <div class="flex items-center h-[85%]    gap-[8px] ">
               <div v-for="(xDevis, index) in xDevisInfo" :key="xDevis.label">
                  <div
                     class="flex flex-col justify-center items-center text-purple-900"
                     :class="
                        index === 1
                           ? 'border-x-[0.5px] px-[16px] py-[4px] '
                           : ''
                     "
                  >
                     <span class="text-[24px] font-[800]  text-purple-900 ">
                        {{ xDevis.value }}
                     </span>
                     <span class="text-center text-[12px] text-purple-900">
                        {{ xDevis.label }}
                     </span>
                  </div>
               </div>
            </div>
         </template>
      </card-with-titile>
   </div>
</template>

<script>
import axios from 'axios';
import URL from '../../request';
import { onMounted, reactive } from '@vue/composition-api';
import cardWithTitile from '../__partiels/cardWithTitile.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';

export default {
   components: { cardWithTitile },

   props: ['month', 'amount', 'percent'],

   setup(props, { root }) {
      const xDevisInfo = reactive([
         {
            value: 0,
            label: 'Devis en attentes de validation',
         },
         {
            value: 0,
            label: 'Valeur des devis en attentes',
         },
         {
            value: 0,
            label: 'Taux de conversion devis en facture',
         },
      ]);

      //
      onMounted(() => {
         qQuote();
      });

      const formatter = (amount) => {
         const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
      };

      const qQuote = async() => {
        try {
            const { data } = await axios.get(URL.DASHBOARD_QUOTE);
            
         Object.keys(data).forEach((DataQuoteKey, index) => {
            
            const el = xDevisInfo[index];
           
            if (DataQuoteKey === 'devis_attente') {
               el.value = data[`${DataQuoteKey}`];
               el.label = 'Devis en attentes de validation';
            } else if (DataQuoteKey === 'montant_devis_attente') {
               el.value = formatter(data[DataQuoteKey]);
               el.label = 'Valeur des devis en attentes';
            } else if (DataQuoteKey === 'taux_devis_to_facture') {
               el.value = data[`${DataQuoteKey}`].toFixed(2) +'%';
               el.label = 'Taux de conversion devis en facture';
            }
         }); 
        } catch (error) {
            console.log(error)
        }
      };

      return {
         xDevisInfo,
      };
   },
};
</script>

<style></style>
