<template>
   <div class="h-full">
      <card-with-titile title="Chiffre d'affaire">
         <template #content>
            <div class="grid grid-cols-3 gap-4">
               <cf-card
                  :month="'Aujourd\'hui'"
                  :amount="state.amountDay"
                  :percent="state.percentDay"
                  :progressingText="'Par rapport au jour precedent'"
               />
               <cf-card
                  :month="'Ce Mois'"
                  :amount="state.amountMonth"
                  :percent="state.percentMonth"
                  :progressingText="'Par rapport au mois precedent'"
               />
               <!-- <cf-card
                        :month="'Six derniers mois'"
                        :amount="state.amountYear"
                        :percent="state.percentYear"
                        :progressingText="'Par rapport au 6 mois precedent'"
                     /> -->

               <cf-card
                  :month="'Cette Annee'"
                  :amount="state.amountYear"
                  :percent="state.percentYear"
                  :progressingText="'Par rapport a l\'année precedente'"
               />
            </div>
         </template>
      </card-with-titile>
   </div>
</template>

<script>
import axios from 'axios';
import URL from '../../request';
import { onMounted, reactive } from '@vue/composition-api';
import cardWithTitile from '../__partiels/cardWithTitile.vue';
import cfCard from '../__partiels/cfCard.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';

export default {
   components: { cardWithTitile, cfCard },

   props: ['month', 'amount', 'percent'],

   setup(props, { root }) {
      const state = reactive({
         // Days
         amountDay: 0,
         percentDay: 0,

         amountMonth: 0,
         percentMonth: 0,

         // Days
         amountYear: 0,
         percentYear: 0,
      });

      //
      onMounted(() => {
         qTurnover();
      });

      const formatter = (amount) => {
         const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
      };

      // GET THE TURNOVER OF COMPAGNY
      const qTurnover = async () => {
         try {
            const { data } = await axios.get(URL.DASHBOARD_TURNOVER);
            console.log(data);
            if (data) {
               // Day
               state.amountDay = formatter(data.jour);
               state.percentDay = data.pourcentage_jour.toFixed(2);

               // Month
               state.amountMonth = formatter(data.mois);
               state.percentMonth = data.pourcentage_mois.toFixed(2);

               // Month
               state.amountYear = formatter(data.annee);
               state.percentYear = data.pourcentage_annee.toFixed(2);
            }
         } catch (error) {
            console.log(error);
         }
      };

      return {
         state,
      };
   },
};
</script>

<style></style>
